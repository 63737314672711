<template>
  <v-menu
    bottom
    offset-y
  >
    <template
      v-slot:activator="{ on }"
    >
      <v-avatar 
        size="32"
        v-on="on"
        v-ripple
        style="cursor: pointer;"
      >
        <img
          v-if="$store.getters.user.photoURL"
          class="no-draggable"
          :src="$store.getters.user.photoURL"
        >
        <svg v-else viewBox="0 0 36 36" fill="none" role="img" xmlns="http://www.w3.org/2000/svg" width="80" height="80"><title>{{ $store.getters.user.displayName }}</title><mask id="mask__beam" maskUnits="userSpaceOnUse" x="0" y="0" width="36" height="36"><rect width="36" height="36" rx="72" fill="#FFFFFF"></rect></mask><g mask="url(#mask__beam)"><rect width="36" height="36" fill="#ff9e9d"></rect><rect x="0" y="0" width="36" height="36" transform="translate(-1 -1) rotate(5 18 18) scale(1.2)" fill="#3fb8af" rx="36"></rect><g transform="translate(-5 -2) rotate(-5 18 18)"><path d="M15 21c2 1 4 1 6 0" stroke="#000000" fill="none" stroke-linecap="round"></path><rect x="14" y="14" width="1.5" height="2" rx="1" stroke="none" fill="#000000"></rect><rect x="20" y="14" width="1.5" height="2" rx="1" stroke="none" fill="#000000"></rect></g></g></svg>
      </v-avatar>
    </template>
    <v-card
      max-width="300px"
    >
      <v-list>
        <v-list-item
          two-line
        >
          <v-list-item-avatar>
            <v-avatar
              size="32"
            >
              <img
                v-if="$store.getters.user.photoURL"
                class="no-draggable"
                :src="$store.getters.user.photoURL"
              >
              <svg v-else viewBox="0 0 36 36" fill="none" role="img" xmlns="http://www.w3.org/2000/svg" width="80" height="80"><title>{{ $store.getters.user.displayName }}</title><mask id="mask__beam" maskUnits="userSpaceOnUse" x="0" y="0" width="36" height="36"><rect width="36" height="36" rx="72" fill="#FFFFFF"></rect></mask><g mask="url(#mask__beam)"><rect width="36" height="36" fill="#ff9e9d"></rect><rect x="0" y="0" width="36" height="36" transform="translate(-1 -1) rotate(5 18 18) scale(1.2)" fill="#3fb8af" rx="36"></rect><g transform="translate(-5 -2) rotate(-5 18 18)"><path d="M15 21c2 1 4 1 6 0" stroke="#000000" fill="none" stroke-linecap="round"></path><rect x="14" y="14" width="1.5" height="2" rx="1" stroke="none" fill="#000000"></rect><rect x="20" y="14" width="1.5" height="2" rx="1" stroke="none" fill="#000000"></rect></g></g></svg>
            </v-avatar>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="font-weight-medium">{{ $store.getters.user.displayName }}</v-list-item-title>
            <v-list-item-subtitle class="text-caption">{{ $store.getters.user.email }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-list
        class="pt-0" 
        v-if="$store.getters.plan"
      >
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="font-weight-bold">
              Build time: {{ getBuildTime }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              Plan: {{ $store.getters.plan.title }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>  
      <v-divider color="#e4e4e4"/>
      <v-list>
        <v-list-item
          @click="showPreferencesDialog"
        >
          <v-list-item-content>
            <v-list-item-title>
              Настройки
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          @click="$emit('logout')"
        >
          <v-list-item-content>
            <v-list-item-title>
              Выход
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>
</template>
<script>
/*global buildTime */

export default {
  props: {
    fields: {
      type: Array,
      required: true
    }
  },
  name: 'UserProfileMenu',
  data: () => ({

  }),
  computed: {
    getBuildTime() {
      return buildTime
    }
  },
  methods: {
    showPreferencesDialog() {
      const data = {}
      const user = this.$store.getters.user
      this.fields.forEach(field => {
        const { name } = field
        data[name] = name !== 'subscription' ? user[name] : user[name].title
      })
  
      this.$emit('showUserPreferencesDialog', data)
    },
  }
}
</script>